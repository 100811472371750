import React, { useEffect, useState } from 'react'

type UserAgent =
    | 'SamsungBrowser'
    | 'Firefox'
    | 'FirefoxiOS'
    | 'ChromeiOS'
    | 'Chrome'
    | 'Safari'
    | 'unknown'

export default function useUserAgent() {
    /**
     * we set our initial state as null because we don't know what the user agent is yet
     * that way we can check if the user agent has been set or not
     */
    const [userAgent, setUserAgent] = useState<UserAgent | null>(null)
    const [userAgentString, setUserAgentString] = useState<string | null>(null)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const userAgentString = window.navigator.userAgent
            setUserAgentString(userAgentString)
            let userAgent

            /**
             * Parse user agent string to determine browser
             * The order of the if statements is important because some browsers
             * have multiple matches in their user agent string
             */
            if (userAgentString.indexOf('SamsungBrowser') > -1) {
                userAgent = 'SamsungBrowser'
            } else if (userAgentString.indexOf('Firefox') > -1) {
                userAgent = 'Firefox'
            } else if (userAgentString.indexOf('FxiOS') > -1) {
                userAgent = 'FirefoxiOS'
            } else if (userAgentString.indexOf('CriOS') > -1) {
                userAgent = 'ChromeiOS'
            } else if (userAgentString.indexOf('Chrome') > -1) {
                userAgent = 'Chrome'
            } else if (userAgentString.indexOf('Safari') > -1) {
                userAgent = 'Safari'
            } else {
                userAgent = null
            }
            setUserAgent(userAgent as UserAgent)
        }
    }, [])

    return { userAgent, userAgentString }
}
