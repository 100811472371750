import useUserAgent from '@/src/hooks/useUserAgent'
import { Drawer, DrawerContent, DrawerOverlay, Flex, Image } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { useState, useEffect } from 'react'

const AddToIosSafari = dynamic(() => import('./AddToHomeScreen/AddToIosSafari'))
const AddToMobileChrome = dynamic(() => import('./AddToHomeScreen/AddToMobileChrome'))
const AddToMobileFirefox = dynamic(() => import('./AddToHomeScreen/AddToMobileFirefox'))
const AddToMobileFirefoxIos = dynamic(() => import('./AddToHomeScreen/AddToMobileFirefoxIos'))
const AddToMobileChromeIos = dynamic(() => import('./AddToHomeScreen/AddToMobileChromeIos'))
const AddToSamsung = dynamic(() => import('./AddToHomeScreen/AddToSamsung'))
const AddToOtherBrowser = dynamic(() => import('./AddToHomeScreen/AddToOtherBrowser'))

type AddToHomeScreenPromptType =
    | 'safari'
    | 'chrome'
    | 'firefox'
    | 'other'
    | 'firefoxIos'
    | 'chromeIos'
    | 'samsung'

const InstallPWADrawer = () => {
    const [displayPrompt, setDisplayPrompt] = useState<AddToHomeScreenPromptType | null>(null)
    const { userAgent } = useUserAgent()

    useEffect(() => {
        if (userAgent === 'Safari') {
            setDisplayPrompt('safari')
        } else if (userAgent === 'Chrome') {
            setDisplayPrompt('chrome')
        } else if (userAgent === 'Firefox') {
            setDisplayPrompt('firefox')
        } else if (userAgent === 'FirefoxiOS') {
            setDisplayPrompt('firefoxIos')
        } else if (userAgent === 'ChromeiOS') {
            setDisplayPrompt('chromeIos')
        } else if (userAgent === 'SamsungBrowser') {
            setDisplayPrompt('samsung')
        } else {
            setDisplayPrompt('other')
        }
    }, [userAgent])

    const Prompt = () => (
        <>
            {displayPrompt !== null
                ? {
                      safari: <AddToIosSafari />,
                      chrome: <AddToMobileChrome />,
                      firefox: <AddToMobileFirefox />,
                      firefoxIos: <AddToMobileFirefoxIos />,
                      chromeIos: <AddToMobileChromeIos />,
                      samsung: <AddToSamsung />,
                      other: <AddToOtherBrowser />,
                  }[displayPrompt]
                : null}
        </>
    )

    return (
        <Drawer size={'full'} placement={'bottom'} isOpen={true} onClose={() => {}}>
            <DrawerOverlay />
            <DrawerContent>
                <Flex
                    height={'100%'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    flexDirection={'column'}
                    padding={4}
                >
                    <Image
                        height={['24px', null, null, '28px']}
                        src="/logos/troops-bw.svg"
                        alt="Troops logo"
                        mb={8}
                    />
                    <Prompt />
                </Flex>
            </DrawerContent>
        </Drawer>
    )
}

export default InstallPWADrawer
