import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const config: ThemeConfig = {
    initialColorMode: 'light',
    useSystemColorMode: false,
}

const theme = extendTheme({
    config,
    fonts: {
        heading: `'Recoleta-Bold', sans-serif`,
        body: `'Inter-Regular', sans-serif`,
    },
    breakpoints: {
        base: '0em', // 0px      (default)
        sm: '30em', // ~480px   (default)
        md: '48em', // ~768px   (default)
        lg: '62em', // ~992px   (default)
        xl: '75em', // ~1200px  (custom)
        '2xl': '96em', // ~1536px  (default)
    },
    colors: { transparent: 'transparent', almostWhite: '#f0f0f0', black: '#000', white: '#fff' },
    components: {
        Button: {
            baseStyle: {},
            variants: {
                primary: {
                    bg: 'black',
                    borderRadius: '9999px',
                    color: 'white',
                    fontWeight: 'semibold',
                    _hover: {
                        bg: 'gray.700',
                    },
                    _disabled: {
                        backgroundColor: 'transparent',
                        borderColor: 'black',
                        border: '2px',
                        color: 'black',
                    },
                },
                'black-outline': {
                    bg: 'whiteAlpha.900',
                    boxShadow: 'inset 0 0 0 2px #000',
                    borderRadius: '9999px',
                    color: 'black',
                    fontWeight: 'semibold',
                    _hover: {
                        bg: 'almostWhite',
                    },
                },
                'troop-gradient': {
                    bg: 'white',
                    borderRadius: '9999px',
                    borderColor: 'black',
                    border: '2px',
                    color: 'black',
                    fontWeight: 'semibold',
                    _hover: {
                        background: 'white',
                        backgroundClip: 'padding-box' /* !importanté */,
                        border: 'solid 3px transparent' /* !importanté */,
                        borderRadius: '9999px',
                        _before: {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            zIndex: -1,
                            margin: '-2px' /* !importanté */,
                            borderRadius: 'inherit' /* !importanté */,
                            background: 'linear-gradient(to right, #0000FF, #FF00FF)',
                        },
                        _after: {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                            zIndex: -1,
                            margin: '-3px' /* !importanté */,
                            borderRadius: 'inherit' /* !importanté */,
                            background: 'linear-gradient(to right, #0000FF, #FF00FF)',
                        },
                    },
                },
            },
        },
    },
})

theme.styles.global = (props: any) => ({
    'html, body': {
        backgroundColor: mode('almostWhite', 'gray.800')(props),
        color: mode('gray.800', 'white')(props),
    },
    a: {
        color: 'teal.500',
    },
})

export default theme
