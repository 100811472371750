import { defineChain } from 'viem'

export const scroll = defineChain({
    id: 534352,
    name: 'Scroll Mainnet',
    network: 'scroll',
    nativeCurrency: {
        decimals: 18,
        name: 'Ether',
        symbol: 'ETH',
    },
    rpcUrls: {
        default: {
            http: ['https://rpc.scroll.io'],
            webSocket: ['wss://wss-rpc.scroll.io/ws/'],
        },
        public: {
            http: ['https://rpc.scroll.io'],
            webSocket: ['wss://wss-rpc.scroll.io/ws/'],
        },
    },
    blockExplorers: {
        default: { name: 'Explorer', url: 'https://scrollscan.com' },
    },
})
